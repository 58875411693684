<template>
    <div class="content">
        <div class="m-card">
            <div class="form">
                <Form
                        :model="formInline"
                        ref="formInline"
                        :rules="ruleValidate"
                >
                    <FormItem label="原密码" :label-width="90" prop="oldPassword" v-if="hasoldPassword">
                        <Input v-model="formInline.oldPassword" password type="password" maxlength="20"
                               placeholder="请输入原密码"/>
                    </FormItem>
                    <FormItem label="新密码" :label-width="90" prop="newPassword">
                        <Input v-model="formInline.newPassword" password type="password" maxlength="20"
                               placeholder="请输入新密码"/>
                    </FormItem>
                    <FormItem label="确认新密码" :label-width="90" prop="confirmPassword">
                        <Input v-model="formInline.confirmPassword" password type="password" maxlength="20"
                               placeholder="请确认新密码"/>


                    </FormItem>
                    <FormItem label="" :label-width="90">
                        <Button type="primary" style="width: 100px;height: 36px;" @click="handleSubmit('formInline')">
                            绑定
                        </Button>
                        <Button style="width: 100px;height: 36px;margin-left: 10px" @click="$router.go(-1)">取消</Button>
                    </FormItem>
                </Form>
            </div>

        </div>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "bind-edit",
        data() {
            const confirm = (rule, value, callback) => {
                if (this.formInline.newPassword) {
                    if (this.formInline.newPassword !== this.formInline.confirmPassword) {
                        callback(new Error('确认密码和新密码不一致'));

                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            };
            const oldPassword = (rule, value, callback) => {
                if (!this.formInline.oldPassword) {
                    callback(new Error('请输入原密码'));
                } else {
                    callback();
                }
            };
            const newPassword = (rule, value, callback) => {
                if (!this.formInline.newPassword) {
                    callback(new Error('请输入新密码'));
                }
                if (this.formInline.newPassword.length < 6) {
                    callback(new Error('密码不得小于6位数'));
                } else {
                    callback();
                }
            };
            return {
                formInline: {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                ruleValidate: {
                    oldPassword: [
                        {validator: oldPassword, trigger: "blur"},
                    ],
                    newPassword: [
                        {validator: newPassword, trigger: "blur"},
                    ],
                    confirmPassword: [
                        {validator: confirm, trigger: "blur"}
                    ]
                },
                hasoldPassword: true


            }
        },
        computed: {
            ...mapGetters({
                userId: "getUserId",
            }),
        },
        created() {
            this.getUserPassword()
        },
        methods: {
            ...mapActions({
                getPassword: "user/getPassword",
                editNewPassword: "user/editNewPassword",
                editPassword:"user/editPassword"
            }),
            async getUserPassword() {
                let data = await this.getPassword(this.userId)
                this.hasoldPassword = data.data
                console.log(data, 'data34')
            },
            async handleSubmit(name) {
                let valid = await this.validForm(name);
                if (!valid) return;
                if (!this.hasoldPassword) {
                    let opt = {
                        userId: this.userId,
                        password: this.formInline.newPassword
                    }
                    let data = await this.editNewPassword(opt)
                    if (data.result) {
                        this.$Message.success('绑定成功')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$Message.warning('绑定失败')
                    }
                }
                else {
                    let opt={
                        userId:this.userId,
                        oldPassword:this.formInline.oldPassword,
                        newpassword:this.formInline.newPassword
                    }
                    let data=await this.editPassword(opt)
                    if(data.result){
                        this.$Message.success('修改成功')
                        this.$router.go(-1);//返回上一层
                    }else{
                        this.$Message.warning(data.message)
                    }
                }
            },

            validForm(name) {
                // 验证表单
                console.log(name, 'name')
                return new Promise((resolve, reject) => {
                    this.$refs[name].validate((valid) => {
                        resolve(valid);
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
    .content {
        min-height: 700px;

        .m-card {
            border: solid 1px #ededed;

            .mc-top-title::before {
                opacity: 0;
                font-size: 16px;
            }
        }

        .form {
            width: 425px;
            padding: 40px;
        }

        ::v-deep {
            .ivu-form-item {
                margin-bottom: 30px;
            }

            .ivu-form {
                .ivu-form-item-label {
                    color: #666;
                }
            }

        }
    }
</style>
